﻿@use "sass:math";
// Mixins
@mixin rem-fallback($property, $values...) {
    $max: length($values);
    $pxValues: '';
    $remValues: '';

    @for $i from 1 through $max {
        $value: strip-unit(nth($values, $i));
        $pxValues: #{$pxValues + $value};

        @if $value != 0 {
            $pxValues: #{$pxValues}px;
        }

        @if $i < $max {
            $pxValues: #{$pxValues + " "};
        }
    }

    @for $i from 1 through $max {
        $value: strip-unit(nth($values, $i));
        $remValues: #{$remValues + math.div($value, 16)};

        @if $value != 0 {
            $remValues: #{$remValues}rem;
        }

        @if $i < $max {
            $remValues: #{$remValues + " "};
        }
    }

    #{$property}: $pxValues;
    #{$property}: $remValues;
}

@mixin px-rem($property, $value) {
    #{$property}: #{$value}px;
    #{$property}: #{math.div($value,16)}rem;
}

@mixin padding($values...) {
    @include rem-fallback(padding, $values...);
}

@mixin margin($values...) {
    @include rem-fallback(margin, $values...);
}

@mixin font-size($value) {
    @include px-rem(font-size, $value);
}

@mixin line-height($value) {
    @include px-rem(line-height, $value);
}

@mixin font-smoothing($value: on) {
    @if $value == on {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    @else {
        -webkit-font-smoothing: subpixel-antialiased;
        -moz-osx-font-smoothing: auto;
    }
}

@function strip-unit($num) {
    @return math.div($num, ($num * 0 + 1));
}
