@use "sass:math";
@import "../modules/_definitions.scss";
@import "../modules/_mixins.scss";

$body-background-color: #F2F2F2;

html body {
    background-color: $body-background-color;
}

.startpage {

    .campaign-container {
        background-color: $color-red;
        margin-bottom: #{$pad * 1.67}px;

        .campaign-heading {
            color: #fff;
            @include font-size(47);
            @include line-height(47);
            font-weight: 700;
        }

        .campaign-text {
            @include font-size(16);
            @include line-height(20);
            color: #fff;
            @include margin($pad * 1.5, 0, 0, 0);
            display: inline-block;
        }

        .link-button-dark-red {
            margin-top: #{$pad*2}px;
        }

        .link-button-lighter-red {
            margin-top: #{$pad*2}px;
        }
    }

    .main-campaign {
        @include padding($pad * 2, $pad);
    }

    .puff-container {
        @include margin(0, 0, $pad * 2, 0);
        @include padding(0, $pad * 1.34);
        float: left;
        width: 100%;
    }

    .puff-image-wrapper {
        height: 200px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .puff-content {
        background-color: #fff;
        @include padding($pad*1.5, $pad, $pad, $pad);

        h2 {
            @include font-size(26);
            @include line-height(29);
        }

        p {
            @include font-size(16);
            @include line-height(19);
            @include margin(0, 0, $pad * 1.5, 0);
        }
    }

    .news-list-container {
        background-color: #fff;
        @include margin(0, 0, $pad * 3, 0);
        @include padding($pad * 2);
        text-align: center;

        ul {
            @include margin(0, 0, $pad * 2.5, 0);
        }
    }

    .news-item {
        @include margin(0, 0, $pad, 0);
    }

    .news-date {
        width: 100%;
        display: inline-block;
        @include font-size(16);
        @include line-height(19);
    }

    .news-link {
        @include font-size(22);
        @include line-height(26);
        font-weight: 600;
    }
}

@media(min-width: $xs-min-width) {
    .startpage {
        .puff-image-wrapper {
            height: 300px;
        }

        .main-campaign {
            @include padding($pad * 3, $pad, $pad * 3, $pad*2.50);
        }
    }
}

@media (min-width: $sm-min-width) {
    .startpage {

        .campaign-container {
            .link-button-dark-red {
                float: left;
                width: auto;
            }

            .link-button-lighter-red {
                float: left;
                width: auto;
            }
        }

        .puff-container {
            @include padding(0);
            display: flex;
            overflow: hidden;
        }

        .puff-image-wrapper {
            width: 50%;
            float: left;
            height: auto;
        }

        .puff-content {
            width: 50%;
            float: left;
            @include padding($pad * 2, $pad * 2);

            .link-button-red {
                margin: 0 auto 0 0;
            }
        }
    }
}

@media (min-width:$md-min-width) {
    .startpage {

        .campaign-container {
            margin-bottom: #{$pad*2}px;

            .campaign-heading {
                @include margin($pad*3, 0, 0, 0);
                @include font-size(60);
                @include line-height(60);
            }

            .campaign-text {
                @include font-size(20);
                @include line-height(24);
                @include margin($pad * 1.5, 0, 0, 0);
            }

            .link-button-dark-red {
                margin-top: #{$pad*3}px;
            }

            .link-button-lighter-red {
                margin-top: #{$pad*3}px;
            }
        }

        .main-campaign {
            @include padding($pad * 3, 0, $pad * 4, $pad * 13);
        }

        .puff-image-wrapper {
            img {
                width: auto;
            }
        }

        .puff-content {
            @include padding($pad * 3);

            h2 {
                @include font-size(36);
                @include line-height(40);
            }

            p {
                @include font-size(20);
                @include line-height(24);
                @include margin(0, 0, $pad * 2, 0);
            }
        }

        .news-list-container {
            @include padding($pad * 3, $pad * 2, $pad * 2, $pad * 2);

            h2 {
                @include font-size(28);
                @include line-height(34);
                @include margin(0, 0, $pad * 1.5, 0);
            }
        }

        .news-item {
            @include margin(0, 0, $pad * 2, 0);
        }

        .news-date {
            @include font-size(16);
            @include line-height(19);
        }

        .news-link {
            @include font-size(20);
            @include line-height(24);
        }
    }
}

@media (min-width: $lg-min-width) {
    .startpage {
        .campaign-container {
            .main-campaign {
                max-width: $site-max-width;
                margin-left: auto;
                margin-right: auto;
            }
        }

        .puff-image-wrapper {
            img {
                width: 100%;
            }
        }
    }
}
