﻿$pad: 15;

$site-max-width: 1280px;
$content-padding-top: 20px;
$content-margin-botton-md: #{$pad*10}px;
$content-margin-bottom-xs: #{$pad*5}px;

$global-font-source-sans-pro: 'Source Sans Pro', Helvetica, Arial;
$font-source-sans-serif: "Source Sans Pro", "Sans-Serif";

$color-black: #000000;
$color-white: #FFFFFF;
$color-lightest-red: #FFDEDE;
$color-light-red: #FFC2C1;
$color-lighter-red: #FFDEDE;
$color-lightest-red: #FFF0F0;
$color-red: #DA2B31;
$color-dark-red: #B42027;
$color-darkest-red: #821F24;
$color-light-grey: #828282;
$link-color: #0072BB;
$link-color-hover: #0c4772;
$link-color-visited: #2e3091;
$background-color-grey: #EBEBEB;
$background-color-white: #FFFFFF;
$important-information-color: #CCCCCC;

// Bootstrap breakpoints

// Extra Small Devices, Phones
$xs-min-width: 480px;

// Small Devices, Tablets
$sm-min-width: 768px;

// Medium Devices, Desktops
$md-min-width: 992px;

// Large Devices, Wide Screens
$lg-min-width: 1200px;
